@import url(https://fonts.googleapis.com/css?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css?family=Karla&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TITLE PAGE STYLES */

#mobileTitle, #mobileSubTitle, #resumeButtonMobileHome, #followFooter, #articleButtonMobile{
  display: none;
}



#testerrrr{
  color:rgb(104, 36, 36);
}

#backgroundContain{
  background-color: white;
  background-image: url(/static/media/bgimg.5c4db287.jpg);
  background-size: cover;
  
}

.NewHomePage{
  background-color: black;
}

#mainContent{
  position: absolute;
  z-index: 2;
  background-color: black;
} 


.homePage{
  background-color: black;
}

#headerText{
  color: red;
  text-align: center;
  margin-top: 25vh;
}

#nameText{
  font-size: 20vh;
}


#navBar{
  background-color: #C3073f!important;
  font-family: 'Ubuntu'!important;
  /* display: none; */
}

.nav-link:hover{
  color:#4e4e50!important;
}

#HomeNav{
  background: none!important;
  font-family: 'Ubuntu'!important;
}

.TitlePageWrapper{
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  z-index: 5;
}

#backgroundImage{
  position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 120%;
    opacity: 0.4;
    z-index: 1;
    overflow:auto;
}

#socialLinks{
  /* position: relative;
  z-index: 1;
  color: white;
  margin-top: 25%; */
  width:40%
}

.socialIcons{
  /* font-size: 3em;
  margin: 1%; */
}

#github{
  margin-right: 6%;
  margin-left: 6%;
}

.iconLinks{
  color: white;
  font-size: 5em;
  /* color:red;
  position: absolute;
  padding: 5px;
  margin-bottom: -1%; */
}

#socialContainer{
  position: absolute;
  bottom: 0;
  right: 38%;
  height: 100px;
  width: 300px;
}

.homeIconLinks{
  color: black;
}

.TitlePageWrapper{
  background: black;
}

.squareIdent{
  color:white;
  font-size: 6em;
  text-align: center;
  text-decoration: underline;
  font-style: italic;
  width: 38%;
  margin-bottom: 5%;
  font-family: 'Ubuntu';
  
}

.squares{
width: 80%;
margin-top: 10%;
}

.homeText{
  color: white;
  font-size: 5em;
  width: 70%;
  text-align: center;
  z-index: 5;
  margin-top: 4%;
  font-family: 'Ubuntu';
}

#three{
  height: 100px;
  width: 100px;
}

#square1:hover {
  opacity: 0.5;
  filter:alpha(opacity=100);
  background-color: white;
  border-color: #C3073f;
  border-radius: 25px;
}

#square2:hover {
  opacity: 0.5;
  filter:alpha(opacity=100);
  background-color: white;
  border-color: #C3073f;
  border-radius: 25px;
}

#square3:hover {
  opacity: 0.5;
  filter:alpha(opacity=100);
  background-color: white;
  border-color: #C3073f;
  border-radius: 25px;
}

#square1{
  border-width: 2px;
  border-color: black;
  background-color: #C3073f;
  border-style: solid;
  height: 100%;
  width: 20%;
  z-index: 5;
  text-align: center;
  color: white;
  border-radius: 25px;
  font-family: 'Ubuntu';
}

#square2{
  border-width: 2px;
  border-color: black;
  background-color: #C3073f;
  border-style: solid;
  height: 100%;
  width: 20%;
  z-index: 5;
  text-align: center;
  color: black;
  border-radius: 25px;
  font-family: 'Ubuntu';
}

#square3{
  border-width: 2px;
  border-color: black;
  background-color: #C3073f;
  border-style: solid;
  height: 100%;
  width: 20%;
  z-index: 5;
  text-align: center;
  color: black;
  border-radius: 25px;
  font-family: 'Ubuntu';
}

#homePageHeader{
  margin-top: 2.5%;
  z-index: 5;
  position: relative; 
  color: white!important;
}

.subPageLinks{
  color:white;
}

.homePageLinks{
  color:white;
}

/* Sub page styles */

#topBorder{
  border-color: #C3073f;
  border-width: 2px 0px 0px 0px;
  border-style: solid;
  padding-top: 10px;
}

#subPageHeader{
  text-align: center;
  margin-top: 2%;
}

#move{
  margin-left: 1%;
}

#subPageName{
  margin-top: 8%;
}

/* Total site styles */

.mainText{
  width: 80%;
}

.quoteContainer{
  padding-top: 8%;
  width: 60%;
  height: 300px;
  text-align: center;
  font-family: 'Caveat', cursive;
  background-image: url(/static/media/marketingpic.20c840bf.jpg);
  background-size: cover;
  margin-top: 2%;
  margin-bottom: 2%;
}

.quoteContainerWT{
  padding-top: 8%;
  width: 60%;
  height: 300px;
  text-align: center;
  font-family: 'Caveat', cursive;
  background-image: url(/static/media/writingpicture.8cbecfec.png);
  background-size: cover;
  margin-top: 2%;
  margin-bottom: 2%;
}

.quoteHeaderWT{
  width: 95%;
}

/* About me Styles */

#aboutMePic{
  width: 500px;
  height: 300px;
  margin-bottom: 2%;
  margin-top: 2%
}

#aboutMePicMobile{
  display:none;
}

.aboutIcon{
  text-align: center;
  margin-bottom: 1%;
  font-size: 3em;
  color: #C3073f;
}

#marketingText{
  width: 50%;
  margin-top: 20px;
}

.textSection{
  width: 70%;
  margin-top: 1%;
}

.aboutMePicBreak{
  height: 100vh;
  width: 100%;
}

#colorbgtest{
  background-color: red;
  width: 70%;
}

.aboutPage,.codingPage,.writingPage,.resumePage,.marketingPage{
  background-color: #1a1a1d;
  background-image: url(/static/media/subbg.e547a578.png);
  color: white;
  font-family: 'Karla';
  font-size: large;
}


.textSection1{
  width: 100%;
  margin-top: 1%;
  border-color: black;
  border-style: dashed;
  border-width: 2px 0px 2px 0px;
  
  

}

.boldWordsTitle{
  color: #C3073f;
}

.picIcon{
  color: #C3073f;
  font-size: 6em;
}

.boldWords{
  color: #950740;
}

a{
  color: #c3073f;
}

a:hover{
  color: #950740;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}


/* Coding Page Styles */

#codePageMB{
  display:none;
}

#codingMobile{
  display: none;
}

.codingText{
  width: 50%;
  margin-top: 20px;
}

#aboutMeText{
  width: 50%;
  margin-top: 20px;
}

#writingText{
  width: 50%;
  margin-top: 20px;
}

.picCarousel{
  width:500px;
  height:300px;
  margin-top: 4%;
}

.projectName{
  color: #c3073f;
}

/* Writing page styles */

.quoteAuthor{
  margin-left: 60%;
  font-size: 1.7em;
}

.quoteAuthorWT{
  margin-left: 60%;
  font-size: 1.7em;
}

/* Resume Page Styles */

#linkButtonResumeMobile{
  display: none;
}

#resumeName{
  text-align: center;
  /* padding-top: 2%; */
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 70%;               
  background-color:#4e4e50;
  height: 1px;
}

.resumeDetails{
  width: 70%
}

#resumeLinks{
  text-align: center;
}

#resumeWrapper{
  border-width: 2px;
  border-color: black;
  border-style: solid;
  width: 90%;
  margin: 5% 5% 5% 5%;
  background-color: white;
  color: black;
  margin-top: 2%;
  padding-top: 2%;
  font-family: Arial, Helvetica, sans-serif;
}

#resumeEmails{
  color: black;
}

#resumeContentWrapper{
  /* padding: 2%; */
}

#resumeButtonMobile{
  display: none;
}

#resumeButtonDesktop{
  margin-bottom: 1%;
  background-color: #c3073f;
  margin: 1%;
}

#resumeButtonDesktopRP{
  margin-bottom: 1%;
  margin-top: 3%;
  background-color: #c3073f;
}

#resumeButtonDesktop:hover{
  background-color: white;
  border-color: #C3073f;
  color: #C3073f;
}

#resumeButtonDesktopRP:hover{
  background-color: white;
  border-color: #C3073f;
  color: #C3073f;
}

@media (max-width: 767px) {

/* Home page specific styles  */

#resumeButtonMobileHome{
  background-color: #c3073f;
  margin-top: 5%;
  margin-bottom: 45%;
  display: inline;
}

#followFooter{
  display: inline;
  color: white;
  margin-bottom: -1%;
}

.homeText, .squareIdent, #square1, #square2, #square3{
  display:none;
}

#mobileTitle{
  color:white;
  display: inline;
  text-align: center;
  font-size: 4em;
  margin-top: 40%;
}

#mobileSubTitle{
  color:white;
  display: inline;
  text-align: center;
}


/* Overall elements */

#navBar{
  display: -webkit-flex;
  display: flex;
}

.mainText{
  width:85%;
}

.subPageLinks{
  display: none;
}

#navLink{
  text-align: center;
}

#nameHeader{
  display: none;
}

/* Resume Specific Styles */

#linkButtonResumeMobile{
  display: inline;
  width: ;
}

#socialLinksmobile{
  height: 2%;
}

.iconLinks{
  font-size: 2.3em;
}

#linkButtonResume{
  width: 100%;
}

.resumeHeaders, .resumeDetails{
  text-align: left;
  width: 90%;
}

#resumeWrapper{
  border-style: none;
}

#resumeContentWrapper{
  padding: 0%;
}

#resumeButtonMobile{
  display: block;
  margin-top: 3%;
  margin-bottom: 8%;
  background-color: #c3073f;
}

#articleButtonMobile{
  display: block;
  margin-top: 5%;
  margin-bottom: 2%;
  background-color: #c3073f;
}

#resumeButtonDesktop{
  display: none;
}

/* About Me Pages */

.textSection{
  width: 90%;
}

#aboutMePic{
  height: 30vh;
  width: 90%;
}

#aboutMePicMobile{
  height: 30%;
  width: 70%;
}

.aboutMePicBreak{
  height: 50vh;
  width: 100%;
}

.picIcon{
  display:none;
}

/* Coding pages */

#codePageMB{
  display: block;
}

#codePageDT{
  display: none;
}

#codingMobile{
  display: inline;
}

#codingDesktop{
  display:none;
}

.codingText{
  width: 85%;
  margin-top: 20px;
}

.picCarousel{
  width: 80%;
  height: auto;
}

/* Writing page */

.quoteContainerWT{
  width: 95%;
}

.quoteHeaderWT{
  margin-top: 2%;
}

.quoteAuthorWT{
  width: 70%;
  margin-left: 32%;
}

/* Marketing page */

.quoteContainer{
  width: 95%;
}
}

